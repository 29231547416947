import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import Headroom from 'headroom.js';
import 'slick-carousel';
import 'magnific-popup';
import $ from 'jquery';
import Instafeed from 'instafeed.js';
import 'waypoints/lib/jquery.waypoints';

const Mangrove = {
  init() {
    this.cache();
    this.events();
    this.preventFuot();
    this.initHeadroom();
    this.initSliders();
    this.initNavPanel();
    this.initCards();
    this.initPagedScroll();
    this.initInstaFeeds();
    this.initGalleries();
    this.initWaypoints();
    this.initParallax();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mainNav = $('.js-nav-main');
    this.$hamburger = $('.js-hamburger');
    this.$instaFeed = document.querySelectorAll('.js-instagram-feed');
  },
  events() {
    this.$doc.on(
      'click',
      '.js-hamburger',
      event => this.showMobileNav(event)
    );
    this.$doc.on(
      'click',
      '.js-share',
      event => this.openShareWindow(event)
    );
    this.$doc.on(
      'click',
      '.js-video',
      event => this.playVideo(event)
    );
    this.$doc.on(
      'click',
      '.js-scroll',
      event => this.scrollToSection(event)
    );
    this.$doc.on(
      'click',
      '.js-toggle-showhide',
      event => this.showStats(event)
    );
    this.$doc.on(
      'click',
      '.js-gallery-thumb',
      event => this.galleryToggle(event)
    );
    this.$doc.on(
      'mouseover',
      '#nav-main li a',
      event => this.displayNavPanel(event)
    )
  },
  initGalleries() {
    $('.gallery').each(function() {
      $(this).magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
          enabled: true,
          fixedContentPos: true
        }
      });
    });
  },
  initParallax() {
    $.fn.isInViewport = function() {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();
      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();
      return elementBottom > viewportTop && elementTop < viewportBottom;
    };
    $(window).on('resize scroll', function() {
      $('.color-block').each(function() {
        var el = $(this);
        if (el.isInViewport()) {
          var scrollTop     = $(window).scrollTop(),
          elementOffset = el.offset().top,
          distance      = (elementOffset - scrollTop);
          var blockPos = distance / 8 + 'px';
          var iconPos = distance / 5 + 'px';
          el.css('transform', 'translateY(' + blockPos + ')');
          el.children().css('transform', 'translateY(' + iconPos + ')');
        }
      });
    });
  },
  initWaypoints() {
    const $animate = $('.js-animate');
    const $fade = $('.js-fadein');
    const $stats = $('.js-stat-anim');

    $animate.waypoint(function(direction) {
      if (direction == 'down') {
        $(this.element).addClass('is-visible');
      }
        this.destroy();
    }, {
      offset: '90%'
    });

    $fade.waypoint(function(direction) {
      if (direction == 'down') {
        $(this.element).addClass('is-visible');
      }
      this.destroy();
    }, {
      offset: '90%'
    });

    $stats.waypoint(function(direction) {
      if (direction == 'down') {
        $(this.element).addClass('is-visible');
      }
      this.destroy();
    }, {
      offset: '50%'
    });
  },
  initInstaFeeds() {
    if (this.$instaFeed.length) {
      this.$instaFeed.forEach((feed) => {
        let foundImages = 0;
        const maxImages = 4;
        const tokens = [];
        // freshideasfood
        tokens['1277146379'] = '1277146379.1677ed0.55b599fdae314fb38c28bb77569f1ac9';
        // freshideasfarms
        tokens['5856814961'] = '5856814961.1677ed0.53e57c3be2f14e598e572cd39c498a81';
        const instafeed = new Instafeed({
          get: 'user',
          clientId: 'bf131388d8cd4d55a1a8fce2fc026756',
          limit: '100',
          userId: feed.dataset.userid,
          accessToken: tokens[feed.dataset.userid],
          target: feed.id,
          resolution: 'standard_resolution',
          success: (response) => {
            // remove hashtags
            response.data.forEach(function(i){
              // let cleanCaption = i.caption.text.replace(/\#\w\w+\s?/g, '');
              let cleanCaption = i.caption.text;
              if (cleanCaption.length > 110) {
                var length = 110;
                i.caption.text = cleanCaption.substring(0, length)+"...";
              } else {
                i.caption.text = cleanCaption
              }
            });
          },
          filter: (image) => {
            if (feed.dataset.type == 'recent_hashtag') {
              if (image.tags.indexOf(feed.dataset.hashtag) >= 0 && foundImages < maxImages) {
                foundImages = foundImages + 1;
                return true;
              }
              return false;
            } else {
              if (foundImages < maxImages) {
                foundImages = foundImages + 1;
                return true;
              }
              return false;
            }

          },
          template: '<a class="instagram-feed__item" title="View on Instagram" target="_blank" href="{{link}}"><div class="instagram-feed__image__outer"><img class="instagram-feed__image" src="{{image}}" /></div><div class="instagram-feed__caption"><p>{{caption}}</p></div></a>'
        });
        instafeed.run();
      });
    }
  },
  initNavPanel() {
    const el = $('#nav-main li.current_page_item');
    const panel_id = el.length ? el.attr('id').replace(/^\D+/g, '') : false
    if (panel_id) {
      $('.nav__panel').removeClass('is-active');
      $('#nav-panel-' + panel_id).addClass('is-active');
    }
  },
  displayNavPanel(event) {
    const el = $(event.currentTarget).parent().attr('id');
    const id = el.replace(/^\D+/g, '');
    $('.nav__panel').removeClass('is-active');
    $('#nav-panel-' + id).addClass('is-active');
  },
  initCards() {
    const cards = document.querySelectorAll('.js-card');
    if (cards.length) {
      Array.prototype.forEach.call(cards, card => {
        let down, up, link = card.querySelector('.js-card-link');
        card.onmousedown = () => down = +new Date();
        card.onmouseup = () => {
          up = +new Date();
          if ((up - down) < 200) {
            link.click();
          }
        }
      });
    }
  },
  initPagedScroll() {
    if ( $('body').is('.paged.blog') || $('body').is('.paged.archive') ) {
      $('html, body').animate({
        scrollTop: $('.blog__main__inner').offset().top-20
      }, 500);
    }
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  playVideo(event) {
    var vid     = $(event.currentTarget).next('video');
    var overlay = $(event.currentTarget);
    $(vid).trigger( $(vid).prop('paused') ? 'play' : 'pause');
    overlay.addClass('hidden');
  },
  showStats(event) {
    var showBtn = $(event.currentTarget);
    showBtn.prev('.js-showhide').slideToggle();
    showBtn.text(showBtn.text() == "See Less" ? "See More": "See Less");
  },
  galleryToggle(event) {
    var thumbTarget = $(event.currentTarget);
    var mainTarget = thumbTarget.parent().prev().children();
    var thumbContent = thumbTarget.html();
    var mainContent = mainTarget.html();
    var wrap = thumbTarget.parent().parent();
    //wrap.find('video').addClass('test');
    //mainTarget.children('video').trigger('pause');
    //mainTarget.children('.js-video').removeClass('hidden');
    mainTarget.html(thumbContent);
    thumbTarget.html(mainContent);
    wrap.find('.js-video').removeClass('hidden');
    wrap.find('video').trigger('pause');
  },
  scrollToSection(event) {
    const el = $(event.currentTarget);
    let section = el.attr('href');
    event.preventDefault();
    if (section) {
      $('html, body').animate({
        scrollTop: $(section).offset().top-20
      }, 1000);
    }
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, {
      'offset': 100,
      'tolearnce': 5
    });
    headroom.init();
  },
  initSliders() {
    $('.js-hero-slider').slick({
      autoplay: true,
      autoplaySpeed: 3000
    });
    $('.js-simple-slider').slick({
      dots: true,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 8000,
      fade: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            autoplay: false
          }
        }
      ]
    });
    $('.js-posts-slider').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      prevArrow: $('.js-arrow-prev'),
      nextArrow: $('.js-arrow-next'),
      responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false
        }
      }
    ]
    });
    $('.js-featured-slider').each(function(key, item) {

      var sliderIdName = 'slider' + key;
      var sliderNavIdName = 'sliderNav' + key;

      this.id = sliderIdName;
      $('.js-featured-slider-nav')[key].id = sliderNavIdName;

      var sliderId = '#' + sliderIdName;
      var sliderNavId = '#' + sliderNavIdName;

      $(sliderId).slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        infinite: true,
        asNavFor: sliderNavId,
        responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            dots: true,
            adaptiveHeight: true
          }
        }
      ]
      });

      $(sliderNavId).slick({
        asNavFor: sliderId,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        prevArrow: $(sliderNavId + ' .js-arrow-prev'),
        nextArrow: $(sliderNavId + ' .js-arrow-next'),
        infinite: true,
        autoplay: true,
        autoplaySpeed: 8000,
        fade: true,
        cssEase: 'linear',
        responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: false,
            autoplay: false
          }
        }
      ]
      });

    });
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mainNav.toggleClass('is-active');
    $('body, html').toggleClass('has-nav-active');
    this.$hamburger.toggleClass('is-active');
    if (this.$mainNav.hasClass('is-active')) {
      this.$hamburger.attr("aria-pressed", "true");
    } else {
      this.$hamburger.removeAttr("aria-pressed");
    }
  },
  preventFuot() {
    this.$doc.ready(() => {
      this.$html.removeClass('no-js').addClass('js');
    });
  },
};

Mangrove.init();
